


import { mapGetters } from 'vuex';
import Vue from 'vue';
import {
  TPromoPageStat,
  TUserStatMeetings,
  TUserStatMessages,
  TUserStatPromoPages
} from '@/_modules/statistics/types/promo-page-stat.type';
import { TColumns } from '@/_modules/statistics/types/column.type';
import { TCompanyRows } from '@/_modules/statistics/types/company/row-company.type';
import { TPromoPage } from '@/_types/promo-page/promo-page.type';
import FileHelper from '@/_helpers/file.helper';

type TPromoPageStatData = {
  columns: TColumns[];
  rows: TCompanyRows[];
  promopages: TUserStatPromoPages;
  meetings: TUserStatMeetings;
  messages: TUserStatMessages;
}

interface IPromoPageStatMethods {
  getTableCellClass: (name: string) => string | void;
  percents: (val: number, total: number) => number;
  order: (val: number, val2: number) => number;
  setData: () => void;
  exportResultCompany: (exportFileFormat: string) => Promise<void>;
}

type TPromoPageStatComputed = {
  promoPage: TPromoPage;
  promoPageStat: TPromoPageStat;
  eventId: number;
  isLoadingCompany: boolean;
  isLoadingPromoPage: boolean;
  isLoading: boolean;
}

const StatisticsCompany = Vue.extend<TPromoPageStatData, IPromoPageStatMethods, TPromoPageStatComputed>({
  name: 'statistics-company',
  computed: {
    ...mapGetters('statisticStore', {
      promoPageStat: 'promoPage',
      isLoadingCompany: 'isLoadingCompany',
    }),
    ...mapGetters('promoPageStore', {
      promoPage: 'promoPage',
      isLoadingPromoPage: 'isPromoPageLoading'
    }),
    eventId(): number {
      return Number(this.$route.params.eventId);
    },
    isLoading(): boolean {
      return !this.promoPageStat || !this.promoPage || this.isLoadingPromoPage || this.isLoadingCompany;
    }
  },
  watch: {
    eventId: {
      immediate: true,
      handler(): void {
        this.$store.dispatch('promoPageStore/getContactPromoPage', this.eventId);
      }
    },
    promoPageStat: {
      immediate: true,
      handler(): void {
        this.setData();
      },
    },
    promoPage: {
      immediate: true,
      handler(): void {
        if (!this.promoPage) { return; }

        this.$store.dispatch('statisticStore/getPromoPage', {
          eventId: this.eventId,
          externalId: this.promoPage.external_id,
        });
      },
    },
  },
  data(): TPromoPageStatData {
    return {
      columns: [
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: this.$t('result.company.viewed_contact'),
          field: 'contactsToMeViewed',
          type: 'number',
          extraClasses: 'left-border',
        },
        {
          label: this.$t('result.company.favorites'),
          field: 'contactsByMeFavorite',
          type: 'number',
        },
        {
          label: this.$t('result.company.meetings_by_me'),
          field: 'meetingsByMeTotal',
          type: 'number',
          extraClasses: 'left-border',
        },
        {
          label: this.$t('result.company.meetings_to_me'),
          field: 'meetingsToMeTotal',
          type: 'number',
        },
        {
          label: this.$t('result.company.total_messages'),
          field: 'messagesTotal',
          type: 'number',
          extraClasses: 'left-border',
        },
        {
          label: this.$t('result.company.sent_messages'),
          field: 'messagesByMeTotal',
          type: 'number',
        },
        {
          label: this.$t('result.company.received_messages'),
          field: 'messagesToMeTotal',
          type: 'number',
        },
      ],
      rows: [],
      promopages: null,
      meetings: {
        by_me: {
          total: 0,
        },
        to_me: {
          total: 0,
        },
        total_confirmed: 0,
        total: 0,
      },
      messages: {
        by_me: {
          total: 0,
        },
        to_me: {
          total: 0,
        },
        total: 0,
      },
    };
  },
  methods: {
    getTableCellClass(name: string): string | void {
      if (name) {
        if (
          name === 'contactsToMeViewed'
          || name === 'meetingsByMeTotal'
          || name === 'messagesTotal'
        ) {
          return 'left-border total-color';
        }
        if (
          name === 'contactsByMeFavorite'
          || name === 'meetingsToMeTotal'
          || name === 'messagesByMeTotal'
        ) {
          return 'high-color';
        }
        if (name === 'messagesToMeTotal') {
          return 'low-color';
        }
      }
    },
    percents(val: number, total: number): number {
      if (total !== 0 && val < total) {
        return (val / total) * 100;
      } else {
        return 100;
      }
    },
    order(val: number, val2: number): number {
      return (val > val2) ? 0 : 1;
    },
    setData(): void {
      if (!this.promoPageStat) { return; }
      this.promopages = this.promoPageStat.List[0].stat.promopages;
      this.promoPageStat.List.forEach(item => {

        this.meetings.by_me.total += item.stat.meetings.by_me.total;
        this.meetings.to_me.total += item.stat.meetings.to_me.total;
        this.meetings.total += item.stat.meetings.by_me.total + item.stat.meetings.to_me.total;
        this.meetings.total_confirmed += item.stat.meetings.by_me.confirmed + item.stat.meetings.to_me.confirmed;

        this.messages.by_me.total += item.stat.messages.by_me.total;
        this.messages.to_me.total += item.stat.messages.to_me.total;
        this.messages.total += item.stat.messages.by_me.total + item.stat.messages.to_me.total;

        const obj = {
          name: item.contact.name,
          contactsToMeViewed: item.stat.contacts.to_me.viewed,
          contactsByMeFavorite: item.stat.contacts.by_me.favorite,
          meetingsByMeTotal: item.stat.meetings.by_me.total,
          meetingsToMeTotal: item.stat.meetings.to_me.total,
          messagesTotal: item.stat.messages.by_me.total + item.stat.messages.to_me.total,
          messagesByMeTotal: item.stat.messages.by_me.total,
          messagesToMeTotal: item.stat.messages.to_me.total,
        };

        this.rows.push(obj);

      });
      this.columns[0].label = `${this.promoPageStat.List.length} ${this.$t('result.company.team_members')}`;
    },
    // this is wrong url for export contacts who visited event
    // async exportEventContacts(exportFileFormat: string): Promise<void> {
    //   const filename = 'EventsWallet_event_' + this.eventId + '_contacts_statistics.' + exportFileFormat;
    //   const file = await this.$store.dispatch('statisticStore/exportEventContacts', {
    //     eventId: this.eventId,
    //     format: exportFileFormat
    //   });
    //   FileHelper.downloadFile(file, filename);
    // },
    async exportResultCompany(exportFileFormat: string): Promise<void> {
      const filename = 'EventsWallet_event_' + this.eventId + '_company_statistics.' + exportFileFormat;
      const file = await this.$store.dispatch('statisticStore/exportResultCompany', {
        eventId: this.eventId,
        externalId: this.promoPage.external_id,
        format: exportFileFormat
      });
      FileHelper.downloadFile(file, filename);
    },
  },
});

export default StatisticsCompany;
