import { render, staticRenderFns } from "./company.html?vue&type=template&id=7b29491c&scoped=true&"
import script from "./company.vue?vue&type=script&lang=ts&"
export * from "./company.vue?vue&type=script&lang=ts&"
import style0 from "./company.scss?vue&type=style&index=0&id=7b29491c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b29491c",
  null
  
)

export default component.exports